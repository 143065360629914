import Swiper, {
    Autoplay,
    EffectFade,
    FreeMode,
    HashNavigation,
    Keyboard,
    Lazy,
    Manipulation,
    Mousewheel,
    Navigation
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';


export const Carousel = () => {

    const changeColor = () => {
        const body = document.body;
        const html = document.documentElement;
        const header = document.querySelector('.header');
        const activeSlide = document.querySelector('.swiper-slide-active');
        const textInfo = document.querySelector('.text-info');
        const additionalText = document.querySelector('.additional');
        const bkgColor = activeSlide.dataset.bkgcolor;
        var textcolor = activeSlide.dataset.textcolor;
        body.style.background = bkgColor;
        html.style.background = bkgColor;
        textInfo.style.color = textcolor;
        additionalText.style.color = textcolor;
        header.style.color = textcolor;
    }

    const slider = document.querySelector(".swiper-container");
    if (slider) {
        const swiperH = new Swiper('.swiper-container', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard, HashNavigation,],
            cssMode: false,
            direction: 'horizontal',
            followFinger: true,
            preloadImages: true,
            allowTouchMove: true,
            loop: true,
            autoplay: {
                delay: 300,
            },
            effect: 'fade',
            watchSlidesProgress: true,
            speed: 1,
            on: {
                init: function () {
                    changeColor();

                },
                slideChangeTransitionStart: function () {
                    changeColor();
                }

            }
        });
    }
}


Carousel();